import { ThemeProvider } from "styled-components";
import GlobalStyles from "../styles/GlobalStyles";
import { dark } from "../styles/Themes";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useEffect, useRef, useState } from "react";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import Gateway from "./_Gateway";
import { AnimatePresence } from "framer-motion";
import ScrollTriggerProxy from '../components/ScrollTriggerProxy';
import Loader from "../components/Loader";


function Gate() {
  const containerRef = useRef(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, [])
  

  return (
    <>
      <GlobalStyles />

      <ThemeProvider theme={dark}>
        {/* <LocomotiveScrollProvider
          options={{
            smooth: true,
            // ... all available Locomotive Scroll instance options
            smartphone:{
              smooth:true,
            },
            tablet:{
              smooth:true,
            }
          }}
          watch={
            [ ]
          }
          containerRef={containerRef}
        > */}
        <AnimatePresence>
        {loaded ? null : <Loader />}
        </AnimatePresence>
        <ScrollTriggerProxy />
          <AnimatePresence>
          <main className='App' data-scroll-container ref={containerRef}>
            <Gateway />
          </main>
          </AnimatePresence>
        {/* </LocomotiveScrollProvider> */}
      </ThemeProvider>
    </>
  );
}

export default Gate;
