import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { Routes, Route } from "react-router-dom";
import { Chain, GateWay, Hero } from "./Pages";
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { configureChains, createClient, WagmiConfig, createStorage } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

// export const DogeChain = {
//   chainId: 2000,
//   chainName: "DogeChain",
//   isTestChain: false,
//   isLocalChain: false,
//   multicallAddress: "0x7bEE57dEbD5ACe4f6A665A7a5e7F4Af5d8E2dA5F",
//   getExplorerAddressLink: (address) =>
//     `https://explorer.dogechain.dog/address/${address}`,
//   getExplorerTransactionLink: (transactionHash) =>
//     `https://explorer.dogechain.dog/tx/${transactionHash}`,
//   // Optional parameters:
//   rpcUrl: "https://rpc01-sg.dogechain.dog",
//   blockExplorerUrl: "https://explorer.dogechain.dog/address/",
//   nativeCurrency: {
//     name: "DogeChain",
//     symbol: "DC",
//     decimals: 18,
//   },
// };

// const config = {
//   readOnlyChainId: DogeChain.chainId,
//   readOnlyUrls: {
//     [DogeChain.chainId]: "https://rpc01-sg.dogechain.dog",
//   },
//   networks: [DogeChain],
// };

export const DogeChain = {
  id: 2000,
  name: "DogeChain",
  network: "Dogechain",
  // iconUrl: 'https://example.com/icon.svg',
  // iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "DogeChain",
    symbol: "wDoge",
  },
  rpcUrls: {
    default: "https://rpc01-sg.dogechain.dog",
  },
  blockExplorers: {
    default: { name: "DogeExploree", url: "https://explorer.dogechain.dog" },
    etherscan: { name: "DogeExplore", url: "https://explorer.dogechain.dog" },
  },
  testnet: false,
};

const { provider, chains } = configureChains(
  [DogeChain],
  [jsonRpcProvider({ rpc: (chain) => ({ http: chain.rpcUrls.default }) })]
);


const wagmiClient = createClient({
  autoConnect: false,
  connector: [new MetaMaskConnector({ chains })],
  provider,
  storage: createStorage({ storage: window.localStorage }),
});

function App() {
  return (
    <>
      <WagmiConfig client={wagmiClient}>
          <GlobalStyles />
          <ThemeProvider theme={dark}>
            <Routes>
              <Route path="/Story" element={<Hero />} />
              <Route path="/chain" element={<Chain />} />
              <Route path="/" element={<GateWay />} />
            </Routes>
          </ThemeProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
