import React from "react";
import styled from "styled-components";

import img1 from "../assets/Images/kimberland.jpg";
import img2 from "../assets/Svgs/gem1.svg";
import img3 from "../assets/Svgs/gem2.svg";

const Section = styled.section`
  position: relative;
  min-height: 100vh;
  width: 80vw;
  display: flex;

  margin: 0 auto;

  @media (max-width: 48em) {
    width: 90vw;
  }
  @media (max-width: 30em) {
    width: 100vw;
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  font-family: "Kaushan Script";
  font-weight: 10;

  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 5;

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(props.theme.fontxxl - 5vw)`};
    top: 0;
    left: 0;
  }
  @media (max-width: 48em) {
    font-size: 4rem;
    color: white;
  }
`;
const Left = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontmd};
  font-weight: 300;
  position: relative;
  z-index: 5;
  margin-top: 20%;

  .overflow {
    color: #fff;
  }

  .overflow1 {
    color: #fff;
  }

  @media (max-width: 70em){
    .overflow1 {
      color: #202020;
    }
  }

  @media (max-width: 64em) {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 10px;

    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    border-radius: 20px;

    .overflow {
      color: #202020;
    }

    .overflow1 {
      color: #202020;
    }
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    width: 80%;
  }
`;
const Right = styled.div`
  width: 50%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }

  .small-img-1 {
    width: 40%;
    position: absolute;
    right: 95%;
    bottom: 10%;
  }
  .small-img-2 {
    width: 40%;
    position: absolute;
    left: 80%;
    bottom: 30%;
  }

  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100vh;
      object-fit: cover;
    }
    .small-img-1 {
      width: 30%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }
    .small-img-2 {
      width: 30%;
      height: auto;
      left: 60%;
      bottom: 20%;
    }
  }
`;

const About = () => {
  return (
    <Section id="fixed-target" className="about">
      <Title
        data-scroll
        data-scroll-speed="-2"
        data-scroll-direction="horizontal"
      >
        You Know Kimberlite?
      </Title>
      <Left data-scroll data-scroll-sticky data-scroll-target="#fixed-target">
        Tutu was a wealthy man of Subiya origin who owned a large farm in south
        central Africa. He lived a very contented life, until an ancient priest
        told him of the existence of diamonds, the world’s most valuable
        mineral. He became so obsessed with the idea of becoming incredibly
        wealthy by finding these precious gemstones that he sold his farm and
        traveled the world in search of them until he had spent all of his
        money.
        <br />
        <br />
        Meanwhile, back at Tutu's property, the new owner took his camel to a
        garden brook to give it a drink of water. While the camel was drinking
        from the brook, he noticed a brightly-colored rock glinting in the
        water. He took it home with him, and placed it on his shelf. A few days
        later, the old priest returned to the property. Seeing the colorful rock
        on the shelf, he recognized it as a large diamond in the rough.
        <br />
        <br />
        Thus was discovered the diamond mine of Kimberlite, one of the most
        magnificent diamond mines in all the history of mankind. Kimberlite Labs
        is a rich celestial body full of diamond mines where the riches are
        endless. Kimberlite diamonds are well-known today as the highest quality
        gemstones in the world. Many of the world’s most famous diamonds are
        believed to be from the Kimberlite mine.
        <br />
        <br />
        Now you can join the adventure of hunting for real diamonds in the
        world's largest diamond crater. Hey, if you find a diamond, IT IS YOURS
        TO KEEP. So what are you waiting for?
        <br />
        <br />
        <span className="overflow1">
          You need a jewel pass to gain VIP access to the treasures within , and
          only 4444 passes would ever exist. Access is only ever granted to a
          few who where brave enough. You must be worthy of the good fortune and
          treasures of{" "}
          <a href="http://twitter.com/Kimberlitelabs">@KimberliteLabs</a>
        </span>
        <br />
        <br />
        <span className="overflow">
          We all get two or three chances in life to be at the right place at
          the right time with the right opportunity and this is one of those
          rare times!!
        </span>
      </Left>
      <Right>
        <img src={img1} alt="Kimberland" />
        <img
          data-scroll
          data-scroll-speed="5"
          src={img2}
          className="small-img-1"
          alt="About Us"
        />
        <img
          data-scroll
          data-scroll-speed="-2"
          src={img3}
          alt="About Us"
          className="small-img-2"
        />
      </Right>
    </Section>
  );
};

export default About;
